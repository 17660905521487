import React from "react";

function RizzoIcon() {
  return (
    <svg width="340" height="337" viewBox="0 0 340 337" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M230.578 307.956C254.838 289.858 272.793 264.59 281.903 235.726C291.012 206.863 290.815 175.866 281.339 147.12C271.863 118.375 253.588 93.3371 229.1 75.5492C204.611 57.7613 175.15 48.1242 144.883 48.0012C114.616 47.8782 85.0771 57.2757 60.445 74.864C35.8129 92.4523 17.3351 117.341 7.62585 146.008C-2.08337 174.676 -2.53235 205.67 6.34243 234.607C15.2172 263.543 32.9664 288.957 57.0787 307.251L72.4902 286.939C52.6385 271.877 38.0256 250.954 30.719 227.131C23.4124 203.307 23.7821 177.789 31.7757 154.187C39.7693 130.586 54.9821 110.095 75.2616 95.6144C95.5412 81.134 119.861 73.397 144.779 73.4983C169.698 73.5995 193.954 81.5338 214.115 96.1785C234.276 110.823 249.322 131.437 257.124 155.103C264.925 178.769 265.087 204.289 257.587 228.052C250.088 251.816 235.305 272.619 215.332 287.519L230.578 307.956Z" fill="white"/>
      <path d="M339.267 86.6337C339.267 75.2568 337.027 63.9913 332.673 53.4804C328.319 42.9695 321.938 33.4191 313.893 25.3744C305.848 17.3297 296.298 10.9483 285.787 6.59459C275.276 2.24084 264.011 -1.85353e-06 252.634 0L252.634 15.5941C261.963 15.5941 271.2 17.4316 279.819 21.0016C288.438 24.5717 296.27 29.8045 302.866 36.4011C309.463 42.9977 314.696 50.8291 318.266 59.448C321.836 68.0669 323.673 77.3046 323.673 86.6337H339.267Z" fill="white"/>
      <path d="M315.311 86.6338C315.311 78.4029 313.69 70.2526 310.54 62.6483C307.39 55.044 302.773 48.1345 296.953 42.3144C291.133 36.4943 284.224 31.8775 276.619 28.7277C269.015 25.5779 260.865 23.9567 252.634 23.9567L252.634 37.7456C259.054 37.7456 265.411 39.0102 271.342 41.467C277.274 43.9239 282.663 47.525 287.203 52.0646C291.743 56.6043 295.344 61.9937 297.8 67.9251C300.257 73.8565 301.522 80.2137 301.522 86.6338H315.311Z" fill="white"/>
      <path d="M291.076 86.6337C291.076 81.622 290.089 76.6594 288.171 72.0292C286.253 67.399 283.442 63.1919 279.898 59.6481C276.354 56.1043 272.147 53.2932 267.517 51.3753C262.887 49.4574 257.924 48.4703 252.912 48.4703V61.8275C256.17 61.8275 259.396 62.4691 262.405 63.7158C265.415 64.9624 268.149 66.7896 270.453 69.0931C272.756 71.3965 274.584 74.1312 275.83 77.1408C277.077 80.1504 277.718 83.3761 277.718 86.6337H291.076Z" fill="white"/>
      <path d="M268.512 86.6337C268.512 84.5486 268.101 82.4838 267.303 80.5574C266.505 78.631 265.336 76.8806 263.861 75.4061C262.387 73.9317 260.636 72.7621 258.71 71.9642C256.784 71.1662 254.719 70.7555 252.634 70.7555L252.634 86.6337H268.512Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M88.0266 124.327H138.725H176.61C205.841 124.327 229.538 148.023 229.538 177.254C229.538 206.485 205.841 230.181 176.61 230.181H138.725V292.58H88.0266V124.327ZM159.061 159.983H139.283V195.082H159.061H159.339V195.08C168.903 194.931 176.61 187.132 176.61 177.533C176.61 167.933 168.903 160.134 159.339 159.985V159.983H159.061Z" fill="white"/>
    </svg>

  );
}

export default RizzoIcon;