import React from "react";

function HexalabIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="196.854"
      height="51.497"
      viewBox="0 0 196.854 51.497"
    >
      <g
        fill="#fff"
        data-name="Grupo 12"
        transform="translate(-205.294 -2144.542)"
      >
        <path
          d="M0 0h6.664v1.739H1.925v1.887H6.1v1.737H1.925v3.389H0z"
          transform="translate(205.294 2187.031)"
        ></path>
        <path
          d="M-5.658-8.83a2.637 2.637 0 001.063-.213 2.442 2.442 0 00.831-.587 2.778 2.778 0 00.537-.869 2.865 2.865 0 00.193-1.056v-.025a2.931 2.931 0 00-.193-1.063 2.657 2.657 0 00-.55-.875 2.655 2.655 0 00-.844-.594 2.567 2.567 0 00-1.063-.219 2.62 2.62 0 00-1.069.213 2.436 2.436 0 00-.825.587 2.763 2.763 0 00-.537.87 2.857 2.857 0 00-.194 1.056v.025a2.9 2.9 0 00.194 1.062 2.634 2.634 0 00.55.876 2.66 2.66 0 00.838.594 2.555 2.555 0 001.069.219m-.025 1.775a4.765 4.765 0 01-1.863-.357 4.614 4.614 0 01-1.469-.963A4.3 4.3 0 01-9.978-9.8a4.49 4.49 0 01-.343-1.756v-.025a4.424 4.424 0 01.35-1.756A4.523 4.523 0 01-9-14.775a4.477 4.477 0 011.475-.975 4.811 4.811 0 011.869-.357 4.768 4.768 0 011.863.357 4.6 4.6 0 011.469.963 4.293 4.293 0 01.963 1.426 4.477 4.477 0 01.344 1.756v.025a4.425 4.425 0 01-.35 1.757 4.519 4.519 0 01-.969 1.437 4.494 4.494 0 01-1.475.975 4.816 4.816 0 01-1.87.357"
          transform="translate(223.429 2202.987)"
        ></path>
        <path
          d="M-4.7-5.158A1.6 1.6 0 00-3.609-5.5a1.141 1.141 0 00.382-.9v-.026a1.114 1.114 0 00-.4-.937 1.767 1.767 0 00-1.113-.308h-1.913v2.513zm-3.879-4.251h4a3.454 3.454 0 012.55.889 2.7 2.7 0 01.751 2v.02A2.744 2.744 0 01-1.8-4.765a3 3 0 01-1.357.982l2.143 3.126h-2.251l-1.875-2.8h-1.513v2.8h-1.926z"
          transform="translate(232.701 2196.44)"
        ></path>
        <path
          d="M-4.08-6.433l-3.363-5.3h2.25L-3.1-8.221l2.125-3.513h2.184l-3.364 5.263v3.489H-4.08z"
          transform="translate(243.455 2198.765)"
        ></path>
        <path
          d="M-5.659-8.83A2.632 2.632 0 00-4.6-9.043a2.436 2.436 0 00.831-.587 2.769 2.769 0 00.538-.869 2.865 2.865 0 00.193-1.056v-.025a2.931 2.931 0 00-.193-1.063 2.657 2.657 0 00-.55-.875 2.657 2.657 0 00-.845-.594 2.564 2.564 0 00-1.063-.219 2.618 2.618 0 00-1.069.213 2.43 2.43 0 00-.825.587 2.776 2.776 0 00-.538.87 2.869 2.869 0 00-.193 1.056v.025a2.913 2.913 0 00.193 1.062 2.645 2.645 0 00.551.876 2.66 2.66 0 00.838.594 2.553 2.553 0 001.069.219m-.025 1.775a4.765 4.765 0 01-1.863-.357 4.617 4.617 0 01-1.469-.963A4.3 4.3 0 01-9.979-9.8a4.49 4.49 0 01-.344-1.756v-.025a4.414 4.414 0 01.351-1.756A4.5 4.5 0 01-9-14.775a4.477 4.477 0 011.475-.975 4.811 4.811 0 011.869-.357 4.763 4.763 0 011.856.357 4.6 4.6 0 011.469.963 4.293 4.293 0 01.963 1.426 4.477 4.477 0 01.344 1.756v.025a4.425 4.425 0 01-.35 1.757 4.519 4.519 0 01-.966 1.436 4.494 4.494 0 01-1.475.975 4.816 4.816 0 01-1.87.357"
          transform="translate(255.798 2202.987)"
        ></path>
        <path
          d="M-4.6-10.786a3.831 3.831 0 01-2.775-.975 3.864 3.864 0 01-1.013-2.913v-5h1.926v4.951a2.32 2.32 0 00.5 1.619 1.79 1.79 0 001.387.543 1.827 1.827 0 001.388-.524 2.21 2.21 0 00.5-1.576v-5.014h1.929v4.939A5.082 5.082 0 01-1.027-13a3.268 3.268 0 01-.77 1.231 3.143 3.143 0 01-1.213.738 4.893 4.893 0 01-1.587.244"
          transform="translate(264.738 2206.706)"
        ></path>
        <path
          d="M-4.7-5.158A1.6 1.6 0 00-3.609-5.5a1.141 1.141 0 00.381-.9v-.026a1.113 1.113 0 00-.4-.937 1.764 1.764 0 00-1.112-.308h-1.913v2.513zm-3.879-4.251h4a3.454 3.454 0 012.551.889 2.7 2.7 0 01.751 2v.02A2.748 2.748 0 01-1.8-4.765a3 3 0 01-1.357.982l2.142 3.126h-2.25l-1.875-2.8h-1.513v2.8h-1.926z"
          transform="translate(274.521 2196.44)"
        ></path>
        <path
          d="M-4.141-8.51a2.985 2.985 0 001.075-.19 2.322 2.322 0 00.831-.537 2.553 2.553 0 00.535-.823 2.8 2.8 0 00.2-1.063v-.026a2.861 2.861 0 00-.2-1.062 2.519 2.519 0 00-.537-.837 2.4 2.4 0 00-.831-.544 2.91 2.91 0 00-1.076-.193h-1.485v5.275zm-3.413-7.014h3.413a5.175 5.175 0 011.882.331 4.444 4.444 0 011.475.919 4.1 4.1 0 01.957 1.382 4.354 4.354 0 01.337 1.719v.025a4.394 4.394 0 01-.338 1.725 4.067 4.067 0 01-.956 1.388 4.52 4.52 0 01-1.476.925 5.088 5.088 0 01-1.882.337h-3.413z"
          transform="translate(286.782 2202.548)"
        ></path>
        <path
          d="M373.77-547.78h1.93v8.751h-1.93z"
          transform="translate(-84.677 2734.804)"
        ></path>
        <path
          d="M-4.506-10.922a5.806 5.806 0 01-1.963-.343 5.224 5.224 0 01-1.751-1.058l1.138-1.363a5.683 5.683 0 001.232.775 3.285 3.285 0 001.381.288 1.677 1.677 0 00.931-.219.689.689 0 00.331-.607v-.025a.751.751 0 00-.069-.331.7.7 0 00-.264-.269 2.778 2.778 0 00-.539-.251q-.344-.125-.9-.263a10.79 10.79 0 01-1.2-.362 3.425 3.425 0 01-.915-.493 1.993 1.993 0 01-.583-.731 2.535 2.535 0 01-.207-1.089v-.025a2.531 2.531 0 01.224-1.081 2.446 2.446 0 01.627-.831 2.8 2.8 0 01.964-.538 3.867 3.867 0 011.236-.187 5.235 5.235 0 011.769.287 5.146 5.146 0 011.481.825l-1 1.451a6.424 6.424 0 00-1.151-.632 2.95 2.95 0 00-1.125-.231 1.365 1.365 0 00-.844.218.667.667 0 00-.281.545v.025a.787.787 0 00.081.369.741.741 0 00.295.282 2.832 2.832 0 00.583.237q.37.112.934.262a9.034 9.034 0 011.185.394 3.3 3.3 0 01.878.525 2 2 0 01.539.719 2.439 2.439 0 01.182.988v.025a2.712 2.712 0 01-.236 1.157 2.365 2.365 0 01-.658.85 2.971 2.971 0 01-1.008.525 4.388 4.388 0 01-1.3.181"
          transform="translate(300.838 2206.823)"
        ></path>
        <path
          d="M-4.7-5.158A1.593 1.593 0 00-3.609-5.5a1.141 1.141 0 00.381-.9v-.025a1.116 1.116 0 00-.4-.937 1.771 1.771 0 00-1.112-.309h-1.913v2.513zm-3.879-4.251h4a3.454 3.454 0 012.551.888 2.7 2.7 0 01.751 2v.021A2.743 2.743 0 01-1.8-4.765a3 3 0 01-1.357.982l2.143 3.125h-2.251l-1.875-2.8h-1.513v2.8h-1.926z"
          transform="translate(309.786 2196.434)"
        ></path>
        <path
          d="M-4.6-10.786a3.831 3.831 0 01-2.775-.975 3.863 3.863 0 01-1.013-2.913v-5h1.926v4.951a2.319 2.319 0 00.5 1.619 1.788 1.788 0 001.387.543 1.827 1.827 0 001.388-.524 2.208 2.208 0 00.5-1.576v-5.013h1.929v4.939A5.082 5.082 0 01-1.027-13a3.278 3.278 0 01-.77 1.231 3.143 3.143 0 01-1.213.738 4.877 4.877 0 01-1.587.244"
          transform="translate(318.494 2206.699)"
        ></path>
        <path
          d="M-4.187-5.355a1.564 1.564 0 001.125-.382 1.25 1.25 0 00.4-.944v-.025a1.2 1.2 0 00-.419-.988 1.776 1.776 0 00-1.143-.336h-1.488v2.675zm-3.45-4.413h3.576a4.223 4.223 0 011.407.218 2.987 2.987 0 011.05.619 2.715 2.715 0 01.663.956 3.185 3.185 0 01.23 1.232v.025a3.076 3.076 0 01-.275 1.339 2.752 2.752 0 01-.757.962 3.289 3.289 0 01-1.125.581 4.764 4.764 0 01-1.381.193h-1.463v2.626h-1.925z"
          transform="translate(327.336 2196.792)"
        ></path>
        <path
          d="M-3.231-2.153h-2.664v-1.775h7.252v1.775h-2.663v6.976h-1.925z"
          transform="translate(333.319 2190.953)"
        ></path>
        <path
          d="M478.121-547.78h1.925v8.751h-1.925z"
          transform="translate(-141.882 2734.804)"
        ></path>
        <path
          d="M0 0h2.125l2.288 6.163L6.7 0h2.076L5.239 8.814h-1.7z"
          transform="translate(339.603 2187.024)"
        ></path>
        <path
          d="M0 0h6.589v1.713H1.9v1.775h4.126V5.2H1.9v1.838h4.751v1.713H0z"
          transform="translate(349.729 2187.024)"
        ></path>
        <path
          d="M538.852-547.476h1.926v8.752h-1.926z"
          transform="translate(-175.174 2734.638)"
        ></path>
        <path
          d="M-4.141-8.51a2.981 2.981 0 001.075-.19 2.325 2.325 0 00.832-.537 2.557 2.557 0 00.534-.823 2.806 2.806 0 00.2-1.063v-.026a2.863 2.863 0 00-.2-1.062 2.519 2.519 0 00-.537-.837 2.4 2.4 0 00-.832-.543 2.894 2.894 0 00-1.075-.194h-1.485v5.275zm-3.413-7.014h3.413a5.172 5.172 0 011.882.331 4.436 4.436 0 011.475.919 4.092 4.092 0 01.957 1.382 4.35 4.35 0 01.337 1.719v.024a4.394 4.394 0 01-.338 1.726 4.067 4.067 0 01-.956 1.388 4.52 4.52 0 01-1.476.925 5.086 5.086 0 01-1.882.338h-3.413z"
          transform="translate(375.345 2202.685)"
        ></path>
        <path
          d="M0 0h6.589v1.713H1.9v1.776h4.126V5.2H1.9v1.838h4.751v1.714H0z"
          transform="translate(377.568 2187.162)"
        ></path>
        <path
          d="M-7.008-6.251l-1.163-2.838-1.162 2.838zM-9.033-11.4h1.775l3.751 8.815H-5.52l-.8-1.963h-3.7l-.8 1.963h-1.963z"
          transform="translate(397.978 2198.501)"
        ></path>
        <path
          d="M-4.505-10.923a5.807 5.807 0 01-1.963-.344 5.2 5.2 0 01-1.75-1.057l1.138-1.363a5.62 5.62 0 001.231.775 3.287 3.287 0 001.381.288 1.673 1.673 0 00.931-.219.689.689 0 00.331-.607v-.025a.751.751 0 00-.069-.331.691.691 0 00-.264-.269 2.817 2.817 0 00-.539-.25q-.345-.125-.9-.263a10.764 10.764 0 01-1.2-.362 3.4 3.4 0 01-.915-.493 2 2 0 01-.583-.731 2.542 2.542 0 01-.207-1.088v-.025a2.533 2.533 0 01.224-1.082 2.451 2.451 0 01.629-.831 2.8 2.8 0 01.964-.537 3.85 3.85 0 011.236-.188 5.235 5.235 0 011.769.287 5.146 5.146 0 011.481.825l-1 1.451a6.394 6.394 0 00-1.15-.631 2.939 2.939 0 00-1.125-.231 1.365 1.365 0 00-.844.219.666.666 0 00-.281.545v.025a.779.779 0 00.082.368.728.728 0 00.294.282 2.8 2.8 0 00.583.237q.37.113.934.263a8.829 8.829 0 011.185.394 3.288 3.288 0 01.877.525 2 2 0 01.539.719 2.441 2.441 0 01.182.988v.025a2.717 2.717 0 01-.236 1.157 2.377 2.377 0 01-.658.85 2.97 2.97 0 01-1.008.525 4.39 4.39 0 01-1.3.182"
          transform="translate(403.452 2206.962)"
        ></path>
        <path
          d="M-4.362-10.365c0-.7-.442-1.092-1.217-1.092h-2.378v-7.451H1.5c2.822 0 4.039 1.093 4.039 3.626V8.061c0 .7.443 1.093 1.217 1.093h5.2c.775 0 1.217-.4 1.217-1.093V6.124h8.851v6.854c0 2.533-1.217 3.625-4.038 3.625H-.324c-2.82 0-4.038-1.093-4.038-3.625z"
          transform="translate(329.177 2163.45)"
        ></path>
        <path
          d="M0 0h8.592v11.225c0 1.291-.1 2.185-.1 2.185h.1a8.784 8.784 0 017.8-4.072c5.164 0 9.187 2.383 9.187 9.585v16.588h-8.594v-15c0-2.384-.943-3.378-2.831-3.378a5.161 5.161 0 00-5.116 3.775 9.884 9.884 0 00-.447 3.278v11.325H0z"
          transform="translate(205.294 2145.149)"
        ></path>
        <path
          d="M-19.155-11.769a3.334 3.334 0 00-3.234-3.519c-2.377 0-3.518 1.711-3.946 3.519zm-3.043-9.7c7.228 0 11.317 5.04 11.317 11.84 0 .809-.19 2.568-.19 2.568h-15.453a5.467 5.467 0 005.705 4.375 12.84 12.84 0 006.753-2.474l3.043 5.754A16.537 16.537 0 01-21.484 4.16c-8.749 0-13.457-6.325-13.457-12.838 0-7.18 4.851-12.791 12.743-12.791"
          transform="translate(264.619 2176.5)"
        ></path>
        <path
          d="M-10-15.669l-8.245-12.914h9.685l2.781 5.712c.348.645.795 1.689.795 1.689h.1s.5-1.043.795-1.689l2.781-5.712h9.68L.177-15.669 8.223-3h-9.436l-3.08-5.761c-.248-.446-.6-1.291-.6-1.291h-.1s-.348.845-.6 1.291L-8.614-3h-9.436z"
          transform="translate(268.706 2183.665)"
        ></path>
        <path
          d="M-13.438-24.526c2.235 0 4.221-2.434 4.221-4.519v-.547h-.794c-2.732 0-5.861.8-5.861 2.931 0 1.192.746 2.136 2.434 2.136m3.775-9.982h.348v-.447c0-2.136-1.639-2.831-3.775-2.831-3.228 0-6.9 2.434-6.9 2.434l-2.98-6.059a19.343 19.343 0 0110.724-3.33c7.2 0 11.522 3.923 11.522 10.43v15.744h-7.847v-1.54a13.432 13.432 0 01.1-1.589h-.1s-1.887 3.725-7.251 3.725c-4.768 0-8.691-3.179-8.691-8.245 0-7.449 10.876-8.294 14.85-8.294"
          transform="translate(300.166 2198.593)"
        ></path>
        <path
          d="M-3.154-10.923l-2.6-4.5 2.6-4.5h5.2l2.6 4.5-2.6 4.5z"
          transform="translate(310.886 2183.839)"
        ></path>
        <path
          d="M-24.1-25.31l-1.788-5.91c-.7-2.235-1.44-5.861-1.44-5.861h-.1s-.746 3.626-1.441 5.861l-1.787 5.91zm-19.866 7.2h.943c.794 0 1.043-.248 1.341-1.092L-32-46.171h9.237l9.685 26.971c.3.844.546 1.092 1.341 1.092h.944v7.45H-16.5c-2.483 0-3.476-.745-4.172-2.88l-1.54-4.57h-10.335l-1.539 4.57c-.7 2.135-1.689 2.88-4.172 2.88h-5.711z"
          transform="translate(389.931 2190.713)"
        ></path>
        <path
          d="M-16.03-34.048h3.179a3.411 3.411 0 003.526-3.725A3.3 3.3 0 00-12.8-41.4h-4.321v6.258a.994.994 0 001.093 1.092m2.434-14.3a2.981 2.981 0 003.029-3.278 2.856 2.856 0 00-3.178-3.029h-3.378v6.307zm-12.417-6.307h-3.228v-7.451H-13.7c7.1 0 12.069 3.179 12.069 9.287a8.516 8.516 0 01-4.119 7.548v.1c4.073 1.241 5.563 4.867 5.563 8C-.187-29.429-6.643-26.6-13.7-26.6h-8.691c-2.533 0-3.626-1.093-3.626-3.625z"
          transform="translate(402.334 2206.652)"
        ></path>
      </g>
    </svg>
  );
}

export default HexalabIcon;