import React from "react";

function WholeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="682.667"
      height="682.667"
      version="1.1"
      viewBox="0 0 180.622 180.622"
    >
      <g transform="translate(-6.24 17.548)">
        <g
          fill="#180523"
          fillOpacity="1"
          stroke="none"
          transform="matrix(.03528 0 0 -.03528 6.24 163.074)"
        >
          <path
            fill="#180523"
            d="M0 2560V0h5120v5120H0zm3393 840l885-875-881-883-882-882-882 882-883 883 548 550c301 302 693 697 872 878 179 180 328 326 332 325 3-2 405-397 891-878z"
          ></path>
          <path
            fill="#180523"
            d="M2157 2862l-337-337 340-340 340-340 337 338 338 337-340 340-340 340z"
          ></path>
          <path
            fill="#ffb0e2"
            stroke="none"
            strokeDasharray="none"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth="7.559"
            d="M242.04 396.733l-116.924-116.93 112.88-113.877C300.08 103.293 352.4 50.97 354.261 49.654l3.385-2.393 117.012 115.567C539.014 226.39 591.818 278.825 592 279.35c.182.524-52.177 53.46-116.354 117.633L358.963 513.662zm160.222-70.481c24.547-24.539 44.63-45.21 44.63-45.938 0-1.738-88.83-90.583-90.568-90.583-.735 0-21.56 20.23-46.276 44.954l-44.94 44.953 45.601 45.615c25.08 25.088 45.898 45.615 46.262 45.615.363 0 20.744-20.077 45.291-44.616z"
            transform="matrix(7.5 0 0 -7.5 -176.885 4622.58)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default WholeIcon;
