import React from "react";

function MupaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="126"
      height="20"
      fill="none"
      viewBox="0 0 126 20"
    >
      <path
        fill="#ddd"
        d="M40.224 0L10.12 16.788 3.172 0H0v20h3.222V8.511L7.978 20h2.864L41.085 3.131V20h3.223V0h-4.084zM62.31 20c-8.4 0-15.233-6.676-15.233-14.88h3.204c0 6.479 5.395 11.75 12.027 11.75 6.631 0 12.027-5.271 12.027-11.75V0h3.203v5.12C77.545 13.324 70.711 20 62.311 20zM82.249 6.811h8.015c1.079 0 1.955-.825 1.955-1.841 0-1.013-.876-1.838-1.955-1.838h-8.015v3.68zm0 13.189h-3.326V0H90.26c2.91 0 5.278 2.23 5.278 4.97 0 2.74-2.367 4.97-5.278 4.97h-8.014V20h.003zM98.618 12.358l3.066-9.205 12.261 9.205H98.618zM102.7 0h-3.273L92.77 20h3.304l1.5-4.504h20.548L124.12 20l1.88-2.508L102.701 0z"
      ></path>
    </svg>
  );
}

export default MupaIcon;