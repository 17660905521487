import React from "react";

function TipaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 61.2 54.42"
    >
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path
            fill="#ddd"
            fillOpacity="1"
            stroke="none"
            strokeOpacity="1"
            d="M56.87 9.75L52.42 5.3A4.17 4.17 0 0052 5c-.19-.21-.38-.42-.58-.62A15.6 15.6 0 0040.13 0c-6.05 0-12.77 2.65-18.69 7.26a4.07 4.07 0 00-8 .11l-.7 4.25a6.15 6.15 0 01-5.07 5.06l-4.25.7a4.08 4.08 0 000 8.05l4.25.7.23.05c-2.18 7.18-1.23 14 3.23 18.51l.38.36.26.28 4.77 4.77a15.55 15.55 0 0011.3 4.32 24.48 24.48 0 006.51-.93 33.74 33.74 0 009-4.09 41.31 41.31 0 007.1-5.69 41.83 41.83 0 005.7-7.11 33.83 33.83 0 004.09-9c2.01-7.21.76-13.7-3.37-17.85zM3.9 22.47a1.08 1.08 0 010-2.13l4.25-.7a8.92 8.92 0 003.29-1.25 9.11 9.11 0 004.05-5.5c.07-.26.15-.51.2-.78l.7-4.25a1.07 1.07 0 012.12 0l.27 1.65.2 1.2.23 1.4c0 .12.07.24.09.37a9 9 0 00.52 1.64 9.07 9.07 0 003.41 4.13 9.09 9.09 0 003.07 1.28 3.54 3.54 0 00.44.11l4.24.7a1.08 1.08 0 010 2.13l-1.5.25-2.74.45a9.27 9.27 0 00-7.54 7.54l-.62 3.79-.07.37V35a1.07 1.07 0 01-2.07.15.38.38 0 01-.05-.15l-.7-4.24a9.11 9.11 0 00-3.2-5.53 8.9 8.9 0 00-1.22-.86 9.77 9.77 0 00-1.42-.68c-.31-.11-.63-.2-.95-.29s-.49-.14-.75-.18zM51 21.11a29.93 29.93 0 01-3.61 8 37.49 37.49 0 01-5.16 6.41 36.66 36.66 0 01-6.41 5.15 29.34 29.34 0 01-7.95 3.61 20.39 20.39 0 01-5.42.8 13.41 13.41 0 01-5.27-1 10.29 10.29 0 01-2.73-1.71c-.18-.15-.35-.3-.51-.46-3.16-3.15-3.87-8.11-2.49-13.49a6.2 6.2 0 011.27 2.82l.7 4.25a4.07 4.07 0 007.34 1.68 3.81 3.81 0 00.4-.71c2.43.89 5.73.37 9.13-1.33A26 26 0 0037 30.2a26 26 0 004.89-6.68c2.24-4.48 2.44-8.8.07-11.16a5.89 5.89 0 00-3.53-1.62 9.76 9.76 0 00-1.22-.07c-3.49 0-7.85 2-11.76 5.41a6.11 6.11 0 01-3.2-4.27C28 6.75 34.59 4 40.13 4a11.69 11.69 0 018.51 3.17 9.45 9.45 0 01.67.75 10.51 10.51 0 011.5 2.49c1.27 3 1.29 6.75.19 10.7zM20 47.85a17.44 17.44 0 002.42.18 24.25 24.25 0 007.82-1.4l3 3c-5.13 1.44-9.94.93-13.24-1.78zM41.24 46L38 42.79a41.2 41.2 0 006.37-5.2l3.28 3.29A37.34 37.34 0 0141.24 46zM52.8 34.46l-3.25-3.25a34.1 34.1 0 003.83-7.73l3 3a29.93 29.93 0 01-3.58 7.98z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default TipaIcon;
