import React from "react";

function MiniminiIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="624.316"
      height="482.667"
      version="1.1"
      viewBox="0 0 165.184 127.706"
    >
      <g transform="translate(-24.804 -51.589)">
        <g
          fill="#ddd"
          stroke="none"
          transform="matrix(.03528 0 0 -.03528 17.083 205.753)"
        >
          <path d="M232 4350c-17-28-18-950 0-968 8-8 58-12 160-12 135 0 148 2 158 19 6 12 10 106 11 233v213l50-100c28-55 58-106 67-112 9-8 46-13 92-13 91 0 89-2 156 130l49 95 3-219c1-141 6-224 13-233 9-10 47-13 149-13 76 0 145 3 154 6 14 5 16 58 16 488 0 362-3 485-12 494-8 8-58 12-159 12H992l-22-27c-12-16-57-93-100-173s-84-153-92-163c-12-17-22-1-106 151-51 94-101 179-111 191-17 20-28 21-167 21-138 0-150-1-162-20z"></path>
          <path d="M1502 4358c-8-8-12-55-12-144 0-111 3-133 16-138 9-3 74-6 145-6h129v-410h-132c-73 0-139-4-146-8-9-7-12-42-10-143l3-134 460-3c253-1 470 0 483 3 21 5 22 9 22 133 0 86-4 132-12 140s-55 12-145 12h-133v410h133c90 0 137 4 145 12 18 18 16 255-2 274-12 12-89 14-473 14-344 0-462-3-471-12z"></path>
          <path d="M2702 4350c-17-28-18-950 0-968 8-8 58-12 158-12s150 4 158 12c9 9 12 68 12 206 0 124 4 191 10 187 5-3 72-86 147-183 76-97 148-187 160-199 21-22 31-23 152-23 71 0 136 3 145 6 14 5 16 58 16 488 0 362-3 485-12 494-8 8-58 12-158 12-142 0-147-1-158-22-8-15-13-95-15-227l-2-205-140 194c-76 107-152 209-168 227l-29 33h-132c-120 0-132-2-144-20z"></path>
          <path d="M3912 4350c-17-28-17-254 1-268 8-7 69-13 148-14l134-3v-400l-134-3c-79-1-140-7-148-14-19-15-19-251 1-267 9-8 144-10 482-9l469 3v280l-142 3-143 3v409h129c71 0 136 3 145 6 13 5 16 27 16 138 0 89-4 136-12 144-9 9-128 12-473 12-448 0-461-1-473-20z"></path>
          <path
            fill="#8f00ff"
            fillOpacity="1"
            d="M232 3048c-9-9-12-129-12-479 0-412 2-469 16-483 12-13 41-16 153-16 93 0 141 4 149 12 9 9 12 75 12 236 0 150 3 221 10 217 6-4 33-51 60-105 27-55 59-104 69-110 21-11 129-14 156-4 9 4 42 57 73 118l57 111 3-225c2-197 4-227 19-237 23-18 273-18 296 0 16 11 17 50 15 492l-3 480-161 3c-143 2-162 1-176-15-8-10-52-85-96-168-44-82-87-158-94-168-12-17-22-2-103 148-50 91-98 175-108 185-16 18-31 20-170 20-106 0-157-4-165-12z"
          ></path>
          <path
            fill="#8f00ff"
            fillOpacity="1"
            d="M1494 3047c-2-7-3-69-2-138l3-124 145-5 145-5v-410l-145-5-145-5-3-129c-2-86 1-133 9-142 16-20 916-21 942-1 15 11 17 29 15 142l-3 130-142 3-143 3v418l143 3 142 3v270l-478 3c-387 2-479 0-483-11z"
          ></path>
          <path
            fill="#8f00ff"
            fillOpacity="1"
            d="M2696 3044c-3-9-6-227-6-484 0-454 1-470 19-480 25-13 281-13 301 0 12 7 16 46 20 211l5 203 100-128c55-71 125-161 155-200 31-39 67-76 80-83 34-17 250-18 273 0 16 11 17 50 15 492l-3 480-154 3c-104 2-158-1-167-9-11-9-14-55-15-223 0-210 0-211-19-186-11 14-76 104-146 200-69 96-136 185-147 198-19 21-28 22-163 22-120 0-143-2-148-16z"
          ></path>
          <path
            fill="#8f00ff"
            fillOpacity="1"
            d="M3906 3044c-9-23-7-237 2-251 5-9 51-13 147-15l140-3v-410l-134-3c-79-1-140-7-148-14-9-7-13-44-13-134 0-112 2-124 19-134 31-16 912-14 934 3 15 11 17 29 15 142l-3 130-143 3-142 3 2 207 3 207 140 5 140 5v270l-476 3c-428 2-477 1-483-14z"
          ></path>
          <path d="M620 1774c-55-11-154-46-188-68-54-34-117-107-133-152-23-66-17-161 14-221 55-108 142-146 450-197 49-8 100-22 114-31 52-35 0-65-112-65-68 0-86 4-120 25-38 23-47 24-212 25-162 0-172-1-178-20-14-44 34-146 92-194 60-50 213-106 290-106 18 0 33-4 33-10s37-10 90-10c50 0 90 3 90 8 0 4 35 13 77 20 88 14 200 63 256 111 126 111 120 333-10 423-61 42-165 73-304 93-145 20-196 33-204 53-11 29 20 45 89 46 52 1 70-3 95-22 30-22 37-22 208-20l178 3 3 24c10 65-72 172-169 221-99 51-148 62-289 65-74 2-146 1-160-1z"></path>
          <path d="M1502 1762c-9-7-12-42-10-143l3-134 143-3 142-3v-409h-134c-102 0-136-3-145-14-8-9-11-56-9-142l3-129h960l3 129c2 86-1 133-9 142-9 11-43 14-145 14h-134v409l143 3 142 3 3 129c2 86-1 133-9 142-9 12-91 14-473 14-253 0-467-4-474-8z"></path>
          <path d="M2662 1758c-8-8-12-54-12-139 0-166-9-159 197-159h153l2-337 3-338 170-3c151-2 171-1 182 15 10 13 13 96 13 339v323l160 3c130 2 163 6 170 18 13 20 13 246 0 271-10 18-27 19-518 19-382 0-511-3-520-12z"></path>
          <path d="M3922 1758c-17-17-17-949 0-966 9-9 129-12 478-12 551 0 500-14 500 136 0 143 24 133-326 136l-289 3-3 43-3 42h244c197 0 247 3 256 14 8 9 11 52 9 127l-3 114-252 3-253 2v90h283c207 0 286 3 295 12 8 8 12 51 12 128s-4 120-12 128c-17 17-919 17-936 0z"></path>
        </g>
      </g>
    </svg>
  );
}

export default MiniminiIcon;
